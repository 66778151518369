<template>
  <ul class="navbar-nav">
    <li
      v-for="(item, i) in defaultItems"
      :key="i"
      class="nav-item"
    >
      <sidenav-collapse
        :nav-text="item.translatedName || item.name"
        :collapse="false"
        :aria-controls="''"
        :collapse-ref="item.link"
        :class="getRoute(item.link) ? 'active' : ''"
      >
        <template #icon>
          <i class="material-icons-round opacity-10">
            {{item.icon}}
          </i>
        </template>
      </sidenav-collapse>
    </li>
    
    <hr class="horizontal light mt-0 mb-2 w-100" />

    <li
      v-for="(item, i) in filteredModules"
      :key="i"
      class="nav-item"
    >
      <sidenav-collapse
        :nav-text="item.translatedName || item.name"
        :collapse="false"
        :aria-controls="''"
        :collapse-ref="item.link"
        :class="getRoute(item.link) ? 'active' : ''"
      >
        <template #icon>
          <i class="material-icons-round opacity-10">
            {{item.icon}}
          </i>
        </template>
      </sidenav-collapse>
    </li>

    <template v-if="showOfficeMenu">
      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item">
        <sidenav-collapse
          nav-text="Importeren"
          :collapse="false"
          :aria-controls="''"
          collapse-ref="/import"
        >
          <template #icon>
            <i class="material-icons-round opacity-10">
              import_export
            </i>
          </template>
        </sidenav-collapse>
      </li>
    </template>
  </ul>
</template>

<script>
import { isOffice } from '@/helpers/ip';
import SidenavCollapse from "@/components/Layout/Sidenav/SidenavCollapse.vue";

import { getAllActive } from '@/api/providers/modules';
import ModuleList from '@/helpers/module-list';

export default {
  name: "SidenavList",

  components: {
    SidenavCollapse,
  },

  data: () => ({
    defaultItems: [
      {
        icon: 'dashboard',
        link: '/dashboard',
        name: 'dashboard',
        translatedName: 'Dashboard'
      },
      {
        icon: 'groups',
        link: '/admins',
        name: 'Admins',
        translatedName: 'Admins',
      },
      {
        icon: 'recent_actors',
        link: '/app-users',
        name: 'app-users',
        translatedName: "App-gebruikers"
      },
      {
        icon: 'lock_person',
        link: '/roles',
        name: 'roles',
        translatedName: 'Rollen & Rechten',
      },
      {
        icon: 'diversity_3',
        link: '/groups',
        name: 'groups',
        translatedName: 'Gebruikersgroepen'
      }
    ],
    filteredModules: [],
    showOfficeMenu: false,
  }),

  created() {
    isOffice()
      .then((showOfficeMenu) => {
        this.showOfficeMenu = showOfficeMenu;
      }
    );

    this.getModules();
  },

  methods: {
    getModules() {
      getAllActive()
        .then(activeModules => {
          const filteredModules = [];
          ModuleList.forEach(m => {
            const activeModule = activeModules.find(am => m.name === am.name);
            
            if (activeModule != null) filteredModules.push({
              ...m,
              translatedName: activeModule.displayName || m.translatedName
            });
          });
          
          this.filteredModules = filteredModules.sort(
            (a, b) => a.translatedName > b.translatedName
            );
        });
    },

    getRoute(currentModuleLink) {
      return this.$route.path.split("/").includes(currentModuleLink.substring(1));
    },
  },
};
</script>